import React from 'react'
import {
  Aws,
  Gcp,
  GitHub,
  JvmIcon,
  NodeJs,
  Nx,
  Python,
  ReactIcon,
  Terraform,
} from './icons'

type Plan = {
  id: string
  logo: string
  name: string
  price: number | string
  href: string
  hrefLabel: string
  mostPopular?: boolean
  features: (string | React.ReactNode)[]
}

const Integrations = [
  { label: 'GCP', logo: Gcp },
  { label: 'AWS', logo: Aws },
  { label: 'GitHub', logo: GitHub },
  { label: 'Terraform', logo: Terraform },
  { label: 'React', logo: ReactIcon },
  { label: 'NodeJS', logo: NodeJs },
  { label: 'Nx', logo: Nx },
  { label: 'Python', logo: Python },
  { label: 'Java/Kotlin', logo: JvmIcon },
]

const plans: Plan[] = [
  {
    id: 'free',
    logo: '/plans/plan_free.png',
    name: 'Free',
    price: 0,
    href: 'https://app.cogo.tools',
    hrefLabel: 'Get Started',
    features: [
      '1 seat',
      '1 project',
      '1 GitHub connection',
      '1 Cloud connection',
      <>
        1 project analysis update <span className="text-gray-500">/month</span>
      </>,
      <>
        1 tool execution <span className="text-gray-500">/month</span>
      </>,
    ],
  },
  {
    id: 'team',
    logo: '/plans/plan_team.png',
    name: 'Team',
    price: 99,
    href: 'https://app.cogo.tools/organization/billing?plan=team',
    hrefLabel: 'Subscribe',
    mostPopular: true,
    features: [
      '3 seats',
      '3 projects',
      '4 GitHub connections',
      '6 Cloud connections',
      <>
        15 project analysis updates{' '}
        <span className="text-gray-500">/month</span>
      </>,
      <>
        15 tool executions <span className="text-gray-500">/month</span>
      </>,
    ],
  },
  {
    id: 'cofounder',
    logo: '/plans/plan_cofounder.png',
    name: 'Co-Founder',
    price: '1% of your company',
    href: 'mailto:hello@cogo.tools',
    hrefLabel: 'Contact Us',
    features: ['Infinite Everything, Zero Limits 🤯'],
  },
]

const App: React.FC = () => (
  <div className="bg-background min-h-screen text-white flex flex-col">
    <header className="flex flex-row p-4 justify-between items-center mb-12">
      <img src="/cogo-logo.png" alt="Logo" className="h-12" />

      <div className="hidden md:flex flex-row gap-6 text-xl font-medium items-center">
        <a href="#features">Features</a>
        <a href="#pricing">Pricing</a>
        <a
          className="px-6 py-1 bg-accent bg-opacity-50 rounded hover:bg-opacity-30 transition-all duration-200 ease-in-out text-center"
          href="https://app.cogo.tools"
        >
          Login
        </a>
      </div>
    </header>
    <main>
      <section className="p-4 flex flex-col items-center text-center justify-center h-full py-12 space-y-12">
        <h1 className="text-4xl font-bold">
          Brainstorm & Launch Software Idea in Minutes 🛠️
        </h1>
        <h3 className="text-xl max-w-full md:max-w-4xl">
          Cogo helps quickly develop and implement software ideas, starting from the brainstorming phase all the way to launch, all in a short amount of time.
        </h3>

        <a
          href="https://app.cogo.tools"
          className="px-8 py-4 bg-accent rounded-2xl hover:bg-opacity-30 transition-all duration-200 ease-in-out h-[14vh] text-center font-bold text-lg border-indigo-200 border"
        >
          Launch Your Idea
        </a>
      </section>

      <section className="p-6 bg-accent bg-opacity-10 text-lg text-center text-indigo-200">
        In fact, we launched Cogo using Cogo! 🤩
      </section>

      <section
        id="features"
        className="flex flex-col h-full py-12 gap-16 bg-black bg-opacity-25"
      >
        <section className="flex m-auto w-full md:w-11/12 flex-col justify-center items-center md:flex-row gap-6 md:gap-12 p-6">
          <div className="flex w-full flex-col gap-6 md:w-1/3">
            <div className="text-center md:text-left space-y-1">
              <h3 className="text-xl font-bold text-accent md:text-2xl">
                Brainstorming
              </h3>
              <p className="text-left">
                Cogo elevates your idea and streamlines your journey to launch,
                delivering:
              </p>
              <ul className="list-disc list-inside text-left">
                <li>
                  <b style={{ color: '#FFC107' }}>Market</b> Analysis
                </li>
                <li>
                  <b style={{ color: '#63ACF4' }}>Competitors</b> Evaluations
                </li>
                <li>
                  Branding including <b style={{ color: '#F5766D' }}>Logo</b> &
                  Color palettes
                </li>
                <li>
                  <b style={{ color: '#EB6DF5' }}>Domain</b> finder
                </li>
                <li>
                  <b style={{ color: '#00F2F2' }}>Pitch Deck</b> Generation
                </li>
              </ul>
            </div>
          </div>
          <div className="flex w-full md:w-2/3">
            <video
              className="rounded-2xl w-full"
              src="/demo/brainstorm.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        </section>
        <h3 className="text-xl text-center font-bold text-purple-100 bg-black bg-opacity-10 p-6">
          😇
          <br />
          Your ideas are safe with us
          <br />
          ...it's execution that truly matters.
        </h3>
        <section className="flex m-auto w-full md:w-11/12 flex-col justify-center items-center md:flex-row-reverse gap-6 md:gap-12 p-6">
          <div className="flex w-full flex-col gap-6 md:w-1/3">
            <div className="text-center md:text-left space-y-1">
              <h3 className="text-xl font-bold text-accent md:text-2xl">
                Software Scaffolding
              </h3>
              <p className="text-left">
                Cogo generates and deploys{' '}
                <b style={{ color: '#F5766D' }}>Real</b>,{' '}
                <b style={{ color: '#FFC107' }}>Custom</b> and{' '}
                <b style={{ color: '#63ACF4' }}>Fully-wired</b> Artifacts , with
                minimum effort.
              </p>
              <p>--</p>
              <p className="text-left">
                Plus, it gives you the source code right into your GitHub
                account... because why not
                <span className="text-accent font-bold"> ¯\_(ツ)_/¯</span>
              </p>
            </div>
          </div>
          <div className="flex w-full md:w-2/3">
            <video
              className="rounded-2xl w-full"
              src="/demo/toolbox.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        </section>
      </section>
      <section
        id="integrations"
        className="p-6 flex flex-col h-full py-12 gap-16"
      >
        <h3 className="text-xl text-center font-bold md:text-2xl text-accent">
          Integrations
        </h3>

        <div className="flex flex-row flex-wrap align-middle items-center justify-center gap-6">
          {Integrations.map(integration => (
            <div
              id={`integration-${integration.label}`}
              key={integration.label}
              title={integration.label}
            >
              <integration.logo className="w-12 h-12" />
            </div>
          ))}
        </div>
      </section>

      <section
        id="pricing"
        className="p-6 flex flex-col h-full py-12 gap-16 bg-backgroundDark"
      >
        <h3 className="text-xl text-center font-bold md:text-2xl text-accent">
          Pricing
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-11/12 md:w-10/12 lg:w-9/12 m-auto">
          {plans.map(plan => (
            <div
              key={plan.id}
              className="flex flex-col w-auto gap-10 p-6 rounded-2xl bg-gradient-to-tr from-backgroundDark to-background"
            >
              <div className="flex flex-col gap-6 w-full">
                <img src={plan.logo} alt={plan.name} className="h-24 w-24" />
                <div className="flex flex-col align-top items-start gap-2 h-full">
                  <h3 className="text-xl text-left text-gray-100">
                    {plan.name} plan
                  </h3>
                  {typeof plan.price === 'number' ? (
                    <p className="text-2xl text-left font-bold text-gray-100">
                      ${plan.price}
                      <span className="text-sm text-gray-400">/month</span>
                    </p>
                  ) : (
                    <p className="text-2xl text-left font-bold text-gray-100">
                      {plan.price}
                    </p>
                  )}
                </div>
                <a
                  href={plan.href}
                  className="px-6 py-2 bg-accent rounded-lg hover:bg-opacity-30 transition-all duration-200 ease-in-out h-[14vh] text-center font-bold text-lg"
                >
                  {plan.hrefLabel}
                </a>
              </div>
              <ul className="list-disc list-inside text-left">
                {plan.features.map((feature, index) => (
                  <li key={index} className="text-gray-100">
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </main>
    <footer className="mt-auto py-4 text-center text-gray-400 bottom-0 sticky bg-background border-t border-accent border-opacity-5">
      © Copyright 2023 Syentist Lab Inc. All rights reserved.{' '}
      <a className="underline" href="/privacy-policy">
        Privacy
      </a>{' '}
      &{' '}
      <a className="underline" href="/terms">
        Terms
      </a>
      .
    </footer>
  </div>
)

export default App
